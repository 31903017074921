
import { ErrorMessages } from '@/helpers/constants'
import {
  getProductInformation,
  generateCodeForExclusiveProduct,
} from '@/services/seller.service'
import { Component, Vue } from 'vue-property-decorator'
import { Validate } from 'vuelidate-property-decorators'
import { required } from 'vuelidate/lib/validators'
import { parseBackendError } from '@/helpers/util'

@Component
export default class QRStatus extends Vue {
  product: any = {}
  securityPhrase: string = ''
  code: string = ''
  isMembership: boolean = false
  limitQuote: number = 0

  @Validate({ required }) reference!: string
  @Validate({ required }) quota: number = 0
  @Validate({ required }) productStatus!: string
  @Validate({ required }) productPayment!: string

  async copyToClipBoard() {
    navigator.clipboard
      .writeText('Tu frase de seguridad es: ' + this.securityPhrase)
      .then(function() {
        Vue.$toast.success('Frase de seguridad copiada con éxito')
      })
  }

  async whatsappShare() {
    const loading = this.$loading.show()
    try {
      const url = `${process.env.VUE_APP_DOMAIN_ECOMMERCE}/event/${this.product.event.slug}?code=${this.code}`
      const message = `${this.product.share_message}\nTu frase de seguridad es: ${this.securityPhrase}`

      const whatsappLink = `https://wa.me/?text=${encodeURIComponent(
        url + '\n' + message
      )}`
      window.open(whatsappLink, '_blank')
    } catch (error) {
      console.log(error)
    }
    loading.hide()
  }

  async getProductInfo() {
    const loading = this.$loading.show()
    try {
      const { data } = await getProductInformation(
        this.$route.params.productUUID
      )
      this.product = data
      this.limitQuote = this.product.event.membership
        ? 1
        : this.product.limit_quote
      this.quota = 1
      this.isMembership = this.product.event.membership ? true : false

      loading.hide()
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
      loading.hide()
    }
  }

  async generateSecurityPhrase() {
    const loading = this.$loading.show()
    try {
      const body = {
        event_id: this.product.event.id,
        quota: this.quota,
      }
      const data: any = await generateCodeForExclusiveProduct(body)

      if (data.data.success) {
        Vue.$toast.success('Frase de seguridad creada con éxito')
        this.code = data.data.code
        this.securityPhrase = data.data.security_phrase.phrase
      }
      loading.hide()
    } catch (error) {
      Vue.$toast.error(parseBackendError(error))
    }
    loading.hide()
  }

  async mounted() {
    await this.getProductInfo()
  }

  get referenceErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.reference.$dirty) return errors
    !this.$v.reference.required &&
      errors.push(ErrorMessages.COMMOM_FIELD_REQUIRED)
    return errors
  }
  get quotaErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.quota.$dirty) return errors
    !this.$v.quota.required && errors.push(ErrorMessages.COMMOM_FIELD_REQUIRED)

    if (this.limitQuote < parseInt(this.$v.quota.$model)) {
      errors.push(ErrorMessages.MAX_QUOTE)
    }

    if (parseInt(this.$v.quota.$model) < 0) {
      errors.push(ErrorMessages.MIN_ZERO)
    }
    return errors
  }
}
